import Header from '../components/Header';
import AdminContents from '../components/AdminContents';
import Footer from '../components/Footer';
import ConfirmModal from '../components/ConfirmModal';
import SyncModal from '../components/SyncModal';
import EditEventModal from '../components/EditEventModal';
import EventModal from '../components/EventModal';
import EventResponsesModal from '../components/EventResponsesModal';

const AdminPage = () => {
    return (
        <div className='page'>
            <EventResponsesModal />
            <EditEventModal />
            <EventModal />
            <ConfirmModal />
            <SyncModal />
            <Header />
            <AdminContents />
            <Footer />
        </div>
    );
};
export default AdminPage;
