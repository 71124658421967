import { Puff } from 'react-loading-icons';
import { AppState } from '../context/AppContext';

const SyncModal = () => {
    const { syncing } = AppState();

    if (!syncing) return;
    return (
        <div className='modal-wrapper'>
            <div className='modal'>
                <div className='center-wrapper'>
                    <Puff stroke='#29292a' className='puff' />
                </div>
            </div>
        </div>
    );
};

export default SyncModal;
