import { AppState } from '../context/AppContext';
import { UserAuth } from '../context/AuthContext';
import {
    convertFromFBTimestamp,
    convertToFBTimestamp,
    updateEvent,
    createEvent,
    countDrivers,
    countGeneral,
    uploadEventResponse,
    createCheckoutSession,
    countPaidGeneral,
    countPaidDrivers,
} from '../script/fbAPI';

import { MdCalendarMonth } from 'react-icons/md';
import { IoPeople } from 'react-icons/io5';
import { FaCar } from 'react-icons/fa6';
import { GrDocumentText } from 'react-icons/gr';
import { FaExternalLinkAlt } from 'react-icons/fa';

import Question from './Question';

import { useState, useEffect } from 'react';

const EventModal = () => {
    const { event, setEvent, setSyncing } = AppState();
    const { user } = UserAuth();

    // Conditional return
    if (!event.data) return;
    if (!(event.type === 'regular')) return;

    // Event properties that require special handling
    const [date, setDate] = useState('');
    const [time, setTime] = useState('');
    const [customQuestionResponses, setCustomQuestionResponses] = useState([]);
    const [openDate, setOpenDate] = useState('');
    const [openTime, setOpenTime] = useState('');

    // Driver questions and data
    const driverQuestions = [
        {
            type: 'radio',
            title: 'Do you have a car?',
            options: ['Nope', 'Yes!'],
        },
        {
            type: 'text',
            title: 'INCLUDING YOURSELF, how many people can your car take?',
        },
    ];
    const [canDrive, setCanDrive] = useState('');
    const [canTakeQty, setCanTakeQty] = useState('');

    // Trip information
    const [viewedTripInfo, setViewedTripInfo] = useState(true); // TODO: update to false and code better form validation

    // Release of liability question and data
    const waiverQuestion = {
        type: 'text',
        title: 'Digital signature of waiver (your full name)',
    };
    const [viewedWaver, setViewedWaver] = useState(true);
    const [signature, setSignature] = useState('');

    // Form validation
    const [checkoutEnabled, setCheckoutEnabled] = useState(false); // TODO: update to false and code better form validation
    const [slotFull, setSlotFull] = useState(false);
    const formIsValid = () => {
        let answeredCustomQuestions = true;
        for (var i = 0; i < customQuestionResponses.length; i++) {
            if (customQuestionResponses[i] === '')
                answeredCustomQuestions = false;
        }
        return (
            viewedTripInfo &&
            viewedWaver &&
            answeredCustomQuestions &&
            signature &&
            ((canDrive && canTakeQty) || !canDrive)
        );
    };
    const slotIsFull = () => {
        if (user.details && user.details.permissions > 2) return false; // Exec can always sign up
        if (canDrive === '') return false;
        if (!canDrive)
            return event.data.general.cur_qty >= event.data.general.max_qty;
        if (canDrive)
            return event.data.drivers.cur_qty >= event.data.drivers.max_qty;
    };
    // Final guard to prevent overbooking, triggered at handleCheckoutClick
    const finalCheckSlotIsFull = async () => {
        if (user.details && user.details.permissions > 2) return false; // Exec can always sign up
        const generalQty = await countGeneral(event.data);
        const driversQty = await countDrivers(event.data);

        if (!canDrive)
            return generalQty >= event.data.general.max_qty
        if (canDrive)
            return driversQty >= event.data.drivers.max_qty

    };
    useEffect(() => {
        if (!event.data) return;
        const full = slotIsFull();
        const formValid = formIsValid();
        const enabled = formValid && !full;
        setCheckoutEnabled(enabled);
        setSlotFull(full);
    });

    // Configure event properties at first render
    useEffect(() => {
        if (!event.data || !user) return;

        // Fill custom responses so no react errors (changing uncrolled input to controlled) - TODO: fix, still happens
        let n = event.data.custom_questions.length;
        let emptyStringsArray = Array.from({ length: n }, () => '');
        setCustomQuestionResponses(emptyStringsArray);

        // Date times require special handling
        if (event.data.date) {
            const temp = convertFromFBTimestamp(event.data.date);
            setDate(temp.date);
            setTime(temp.time);
        } else {
            setDate('');
            setTime('');
        }
        if (event.data.open) {
            const temp = convertFromFBTimestamp(event.data.open);
            setOpenDate(temp.date);
            setOpenTime(temp.time);
        } else {
            setOpenDate('');
            setOpenTime('');
        }
    }, [event]);

    // Cancel button click
    const handleCancelClick = () => {
        setEvent({});
    };
    //
    // Checkout button click
    const handleCheckoutClick = async () => {
        // TODO: Visual form validation
        if (!formIsValid()) return;
        // Final guard check to see if form is full. Should prvent overbooking
        const full = await finalCheckSlotIsFull();
        if (full) {
            alert("We're sorry, but this event is now full!")
            return;
        }

        // Upload form responses to firebase
        const response = {
            custom_question_responses: customQuestionResponses,
            can_drive: canDrive,
            can_take_qty: canTakeQty ? canTakeQty : 0,
            signature: signature,
        };
        setSyncing(true);
        await uploadEventResponse(user, event.data, response);

        // Create checkout session
        let price_id = event.data.general.price_id; // General price
        if (user.details && 
            user.details.permissions > 1 &&
            event.data.staff &&
            event.data.staff.price_id
        )
            price_id = event.data.staff.price_id;
        await createCheckoutSession(user, price_id);
    };

    // Set custom question response
    const handleSetCustomQuestionResponse = (i, response) => {
        let temp = [...customQuestionResponses];
        temp[i] = response;
        setCustomQuestionResponses(temp);
    };

    return (
        <div className='modal-wrapper'>
            <div className='modal'>
                <div className='center-wrapper'>
                    <div className='event-edit-container'>
                        {/* Event details */}
                        <div>
                            <h2>{event.data.title}</h2>
                            <div className='event-glance-wrapper'>
                                <div>
                                    <MdCalendarMonth className='glance-icon' />
                                    {event.data.show_date_time && (
                                        <p>{`${date} at ${time}`}</p>
                                    )}
                                    {!event.data.show_date_time && (
                                        <p>{`${date}`}</p>
                                    )}
                                </div>
                                {event.data.type === 'cabinTrip' && (
                                    <div>
                                        <div>
                                            <IoPeople className='glance-icon' />
                                            <p>{`${event.data.general.cur_qty}/${event.data.general.max_qty} non-driver spots filled`}</p>
                                        </div>
                                        <div>
                                            <FaCar className='glance-icon' />
                                            <p>{`${event.data.drivers.cur_qty}/${event.data.drivers.max_qty} driver spots filled`}</p>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <p
                                className='event-desc-long'
                                dangerouslySetInnerHTML={{
                                    __html: event.data.desc_long,
                                }}
                            />
                            {(event.data.attending || 
                             (event.data.type === 'party' && 
                              user.details.permissions > 0
                               )) && (
                                <div>
                                    {(event.data.type === 'party' && 
                                    user.details.permissions > 0) && (
                                        <p className='margin-bottom'>
                                            As a member, you get free access to the party!
                                        </p>
                                    )}
                                    {!(event.data.type === 'party' && 
                                    user.details.permissions > 0) && (
                                        <p className='margin-bottom'>
                                            You've already filled out this form and your payment has been confirmed.
                                        </p>
                                    )}
 
                                    {/* Cabin trip information */}
                                    {event.data.type === 'cabinTrip' && (
                                        <div>
                                            <p>
                                                View the cabin trip information below.
                                            </p>
                                            <a
                                                target='_blank'
                                                href={event.data.trip_doc_url}
                                            >
                                                <button
                                                    className='secondary external-doc-wrapper'
                                                    onClick={() =>
                                                        setViewedTripInfo(true)
                                                    }
                                                >
                                                    <GrDocumentText className='external-doc-icon' />
                                                    <p>View</p>
                                                    <FaExternalLinkAlt className='inline-icon' />
                                                </button>
                                            </a>
                                        </div>
                                    )}
                                    {/* Waiver */}
                                    <p>
                                        View our release of liability waiver
                                        below.
                                    </p>
                                    <a
                                        target='_blank'
                                        href='https://docs.google.com/document/d/1GKpRiubdgE6lcKt999VnIR66e9OiEu0lJi8hk9ISW0I/edit?usp=sharing'
                                    >
                                        <button
                                            className='secondary external-doc-wrapper'
                                            onClick={() => setViewedWaver(true)}
                                        >
                                            <GrDocumentText className='external-doc-icon' />
                                            <p>View</p>
                                            <FaExternalLinkAlt className='inline-icon' />
                                        </button>
                                    </a>
                                </div>
                            )}
                            {!(event.data.attending || 
                             (event.data.type === 'party' && 
                              user.details.permissions > 0
                               )) && (
                                <div>
                                    {/* Cabin trip information */}
                                    {event.data.type === 'cabinTrip' && (
                                        <div>
                                            <p>
                                                Please read the cabin trip
                                                information below carefully.
                                            </p>
                                            <a
                                                target='_blank'
                                                href={event.data.trip_doc_url}
                                            >
                                                <button
                                                    className='secondary external-doc-wrapper'
                                                    onClick={() =>
                                                        setViewedTripInfo(true)
                                                    }
                                                >
                                                    <GrDocumentText className='external-doc-icon' />
                                                    <p>View</p>
                                                    <FaExternalLinkAlt className='inline-icon' />
                                                </button>
                                            </a>
                                            {/* Can you drive? */}
                                            <Question
                                                data={driverQuestions[0]}
                                                value={canDrive}
                                                callback={setCanDrive}
                                            />
                                            {/* If so, how many can you take? */}
                                            {canDrive == 1 && (
                                                <Question
                                                    data={driverQuestions[1]}
                                                    value={canTakeQty}
                                                    callback={setCanTakeQty}
                                                />
                                            )}
                                        </div>
                                    )}
                                    {/* Waiver */}
                                    <p>
                                        Please read the release of liability
                                        waiver below.
                                    </p>
                                    <a
                                        target='_blank'
                                        href='https://docs.google.com/document/d/1GKpRiubdgE6lcKt999VnIR66e9OiEu0lJi8hk9ISW0I/edit?usp=sharing'
                                    >
                                        <button
                                            className='secondary external-doc-wrapper'
                                            onClick={() => setViewedWaver(true)}
                                        >
                                            <GrDocumentText className='external-doc-icon' />
                                            <p>View</p>
                                            <FaExternalLinkAlt className='inline-icon' />
                                        </button>
                                    </a>
                                    <Question
                                        data={waiverQuestion}
                                        value={signature}
                                        callback={setSignature}
                                    />
                                    {/* Custom questions */}
                                    {event.data.custom_questions.map(
                                        (question, i) => (
                                            <Question
                                                key={i}
                                                data={question}
                                                value={
                                                    customQuestionResponses[i]
                                                }
                                                callback={(response) =>
                                                    handleSetCustomQuestionResponse(
                                                        i,
                                                        response
                                                    )
                                                }
                                            />
                                        )
                                    )}
                                </div>
                            )}
                        </div>
                        {/* Action buttons */}
                        <div className='confirm-button-grid'>
                            <button
                                className='secondary'
                                onClick={handleCancelClick}
                            >
                                Cancel
                            </button>
                            {!(event.data.attending || 
                             (event.data.type === 'party' && 
                              user.details.permissions > 0
                               )) && (
                                <button
                                    className={`primary ${
                                        checkoutEnabled ? '' : 'disabled'
                                    }`}
                                    onClick={handleCheckoutClick}
                                    disabled={!checkoutEnabled}
                                >
                                    {!slotFull && 'Checkout'}
                                    {slotFull && 'Event full'}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EventModal;
