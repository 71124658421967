import { AppState } from '../context/AppContext';
import { updateParam } from '../script/fbAPI';

import { useState } from 'react';

const ConfirmModal = () => {
    const { confirm, setConfirm } = AppState();
    if (!confirm.actionRequired) return;

    const handleCancelClick = () => {
        setConfirm({
            ...confirm,
            actionRequired: false,
        });
    };

    const handleConfirmClick = async () => {
        setConfirm({
            ...confirm,
            actionRequired: false,
        });
        if (confirm.callback) await confirm.callback();
    };

    return (
        <div className='modal-wrapper'>
            <div className='modal'>
                <div className='center-wrapper'>
                    <div className='confirm-box'>
                        <p>
                            {confirm.text
                                ? confirm.text
                                : 'Are you sure you want to do this action?'}
                        </p>
                        {!confirm.onlyConfirm && (
                            <div className='confirm-button-grid'>
                                <button
                                    className='primary'
                                    onClick={handleCancelClick}
                                >
                                    Cancel
                                </button>
                                <button
                                    className='secondary'
                                    onClick={handleConfirmClick}
                                >
                                    Confirm
                                </button>
                            </div>
                        )}
                        {confirm.onlyConfirm && (
                            <div className='confirm-button-grid only-confirm'>
                                <button
                                    className='primary'
                                    onClick={handleConfirmClick}
                                >
                                    Confirm
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
