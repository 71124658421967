import { useContext, createContext, useState, useEffect } from 'react';

import InApp from 'detect-inapp';

const AppContext = createContext();

export const AppContextProvider = ({ children }) => {
    const [syncing, setSync] = useState(false);
    const [native, setNative] = useState('');
    const [confirm, setConfirm] = useState({});
    const [event, setEvent] = useState({});
    const [verified, setVerified] = useState(false);

    const setSyncing = (state) => {
        setSync(state);
    };

    const checkNative = () => {
        const inapp = new InApp(
            navigator.userAgent || navigator.vendor || window.opera
        );
        const browsers = ['chrome', 'safari', 'ie', 'firefox', 'other'];
        const inBrowsers = browsers.includes(inapp.browser);

        setNative(inBrowsers);
    };

    useEffect(() => {
        checkNative();
    }, []);

    return (
        <AppContext.Provider
            value={{
                syncing,
                setSyncing,
                confirm,
                setConfirm,
                native,
                event,
                setEvent,
                verified,
                setVerified,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const AppState = () => {
    return useContext(AppContext);
};
