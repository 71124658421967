import group from '../assets/images/group.png';
import { UserAuth } from '../context/AuthContext';
import { AppState } from '../context/AppContext';

import { Link } from 'react-router-dom';

const HomeContents = () => {
    const { user, googleSignIn } = UserAuth();
    const { native } = AppState();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='h-center'>
            <div className='content-wrapper'>
                <img className='group-image' src={group} alt='Group photo' />
                <div className='info-wrapper'>
                    <h2 id='about'>Who We Are</h2>
                    <p>
                        Upon arriving at school freshman year, a close-knit
                        group of friends sharing a passion for snow sports went
                        in search of the various ski/snowboard opportunities
                        UCLA had to offer. To their surprise UCLA didn't have a
                        ski club - only a ski team. Although the team is well
                        established and offers great boarding opportunities, its
                        sign-up cost and enrollment in competitions didn't
                        identify with the crew. They were interested in a
                        cheaper, more laid back atmosphere where people could
                        just kick it and hit the slopes. LASBC is now the
                        largest social club on campus.
                    </p>
                    <br />
                    <p>
                        Los Angeles Ski and Board Club offers ski trips for all
                        levels of skiers and snowboarders with plenty of fun
                        after the slopes close. Our goal is to provide the
                        cheapest and most rad trips up to the mountains.
                    </p>
                    <br />
                    <h2 id='about'>Become a Member</h2>
                    {!user && (
                        <p>Interested in becoming a member? Sign in below:</p>
                    )}
                    {user && <p>View your member dashboard to get started:</p>}
                    {!user && native && (
                        <button
                            className='primary'
                            onClick={handleGoogleSignIn}
                        >
                            Sign In
                        </button>
                    )}
                    {!user && !native && (
                        <Link to='/uhoh'>
                            <button className='primary'>Sign In</button>
                        </Link>
                    )}
                    {user && (
                        <Link to='/dashboard'>
                            <button className='primary'>
                                Member Dashboard
                            </button>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};

export default HomeContents;
