import { useContext, createContext, useState, useEffect } from 'react';
import {
    GoogleAuthProvider,
    signInWithRedirect,
    signOut,
    getRedirectResult,
    onAuthStateChanged,
    getAdditionalUserInfo,
} from 'firebase/auth';
import { auth } from '../script/fbConfig';
import { handleNewUser, getUserDetails, userDoesExist } from '../script/fbAPI';
import { AppState } from '../context/AppContext';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState({});
    const { setSyncing } = AppState();

    const googleSignIn = async () => {
        const provider = new GoogleAuthProvider();
        const result = await signInWithRedirect(auth, provider);
    };

    const logOut = () => {
        signOut(auth);
    };

    const attemptGetUserDetails = async (user) => {
        const userDetails = await getUserDetails(user);
        setUser({
            ...user,
            details: userDetails,
        });
        setSyncing(false);
    };

    useEffect(() => {
        const userChange = onAuthStateChanged(auth, async (currentUser) => {
            if (!currentUser) setUser(currentUser);
            else {
                // Bug fix: ensures user does exist!
                const exists = await userDoesExist(currentUser);
                if (!exists) await handleNewUser(currentUser);

                attemptGetUserDetails(currentUser);
            }
        });
        return async () => {
            userChange();
        };
    }, []);

    return (
        <AuthContext.Provider value={{ googleSignIn, logOut, user }}>
            {children}
        </AuthContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(AuthContext);
};
