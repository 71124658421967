import { AiOutlineLock } from 'react-icons/ai';

const DataPlaceholder = ({ locked }) => {
    return (
        <div className='info'>
            {!locked && <p>Coming Soon</p>}
            {locked && (
                <div className='locked-wrapper'>
                    <AiOutlineLock className='lock-icon' />
                    <p>
                        This section will be visible once all profile
                        information above is filled out
                    </p>
                </div>
            )}
        </div>
    );
};

export default DataPlaceholder;
