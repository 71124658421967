import { useState } from 'react';

const MerchItem = ({ item }) => {
    const images = require.context('../assets/images/merch', true);
    const [variant, setVariant] = useState(0);
    const [image, setImage] = useState(0);

    const getImageSrc = (src) => {
        return images(`./${src}`);
    };

    const handleVariantChange = (k) => {
        setVariant(k);
    };

    const handleImageUp = () => {
        if (item.variants[variant].images.length > 1) setImage(1);
    };
    const handleImageDown = () => {
        setImage(0);
    };

    if (!item.live) return;

    return (
        <div className='item-wrapper'>
            <a className='block' href={item.link} target='_blank'>
                <div
                    className='merch-thumbnail-wrapper'
                    onMouseEnter={handleImageUp}
                    onMouseLeave={handleImageDown}
                >
                    <img
                        className='merch-thumbnail'
                        src={getImageSrc(item.variants[variant].images[image])}
                    />
                </div>
            </a>

            <div className='item-info-wrapper'>
                <div className='name-price-wrapper'>
                    <p className='merch-name'>{item.name}</p>
                    {item.limited && <p className='limited-tag'>Almost gone</p>}
                    {/* <p className='merch-price'>{item.price}</p> */}
                </div>
                <div className='variants-wrapper'>
                    {item.variants.map((variant, k) => (
                        <button
                            key={k}
                            className='variant-color'
                            style={{
                                backgroundColor: variant.color,
                            }}
                            onMouseEnter={() => handleVariantChange(k)}
                            onClick={() => handleVariantChange(k)}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MerchItem;
