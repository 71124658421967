import { getEvents } from '../script/fbAPI';
import { useState, useEffect } from 'react';
import { AppState } from '../context/AppContext';

import EventsCollection from './EventsCollection';

import { UserAuth } from '../context/AuthContext';

import { IoMdAdd } from 'react-icons/io';

const EditEventsPanel = () => {
    const [events, setEvents] = useState([]);
    const { setEvent, setSyncing } = AppState();
    const { user } = UserAuth();

    useEffect(() => {
        handleGetEvents();
    }, []);

    const handleGetEvents = async () => {
        const data = await getEvents(user, true);
        for (var i = 0; i < data.length; i++) data[i].edit = true;
        setEvents(data);
    };

    const handleCreateEvent = () => {
        setEvent({
            data: {},
            callback: handleGetEvents,
            type: 'edit',
        });
    };

    return (
        <div className='info-wrapper edit-events-wrapper'>
            <div className='flex'>
                <h2>Edit Events</h2>
                <div className='inline-block'>
                    <button
                        className='inline margin-left'
                        onClick={handleCreateEvent}
                    >
                        Add
                        <IoMdAdd className='inline-icon' />
                    </button>
                </div>
            </div>
            <EventsCollection events={events} editable={true} />
        </div>
    );
};

export default EditEventsPanel;
