import { MdPersonRemove } from 'react-icons/md';
import { AppState } from '../context/AppContext';
import { updateParam } from '../script/fbAPI';

const RemoveMember = (props) => {
    const { setSyncing, setConfirm } = AppState();

    const handleRemoveMember = async () => {
        const user = props.data;
        const confirm = {
            actionRequired: true,
            text: `Remove ${user.name}?`,
            user: user,
            callback: removeMemberCallback,
        };
        await setConfirm(confirm);
    };

    // Remove member callback
    const removeMemberCallback = async () => {
        const user = props.data;
        if (user) {
            setSyncing(true);
            await updateParam(user, 'permissions', 0);
            await updateParam(user, 'removed', true);
            setSyncing(false);
            setConfirmActionRequired(false);
        } else {
            alert('An error occured. Please refresh the page.');
        }
    };

    return (
        <div>
            <button onClick={handleRemoveMember}>
                <MdPersonRemove className='inline-icon-medium' />
            </button>
        </div>
    );
};

export default RemoveMember;
