// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: 'AIzaSyD1TwiRhq6r6GYAvTxTC-ER354MDOKnqjI',
    // authDomain: 'lasbc-71b12.firebaseapp.com',
    authDomain: 'laboardclub.com',
    projectId: 'lasbc-71b12',
    storageBucket: 'lasbc-71b12.appspot.com',
    messagingSenderId: '31313832298',
    appId: '1:31313832298:web:9b7987a846edfbade4dd13',
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app);
export const storage = getStorage(app);

export const lnkref = 'https://forms.office.com/r/YJUqx4dBdF';
