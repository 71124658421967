import Logo from '../assets/images/logo.png';
import { BsInstagram, BsTiktok } from 'react-icons/bs';

const Footer = () => {
    return (
        <div className='footer-wrapper'>
            <div className='h-center'>
                <div className='content-wrapper'>
                    <div className='footer-contents'>
                        <div className='socials-wrapper'>
                            <a
                                href='https://www.instagram.com/laboardclub/?hl=en'
                                target='_blank'
                            >
                                <BsInstagram className='socials-icon' />
                            </a>
                            <a
                                href='https://www.tiktok.com/@laskiandboardclub'
                                target='_blank'
                            >
                                <BsTiktok className='socials-icon' />
                            </a>
                        </div>
                        <p className='copyright'>
                            Copyright 2024 Ski Board @ UCLA
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
