import NavBar from './NavBar';
import Banner from './Banner';

const Header = () => {
    return (
        <div className='header'>
            <NavBar />
            <Banner />
        </div>
    )
}

export default Header;