import { useState, useEffect } from 'react';
import { getAge } from '../script/fbAPI';

const MemberAge = (props) => {
    const [highlight, setHighlight] = useState(false);
    const [age, setAge] = useState('');

    useEffect(() => {
        if (props.data.dob) {
            const age = getAge(props.data.dob);

            setAge(age.toString());
            if (age >= 18 && age <= 24) setHighlight(true);
            setAge(age.toString());
            if (age >= 18 && age <= 24) setHighlight(true);
        } else {
            setAge('');
        }
    }, []);

    return (
        <div>
            <p className={highlight ? 'highlight-green' : 'highlight-red'}>
                {age}
            </p>
        </div>
    );
};

export default MemberAge;
