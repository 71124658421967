import { getCurrentTimeMillis } from '../script/fbAPI';

import { useState, useEffect } from 'react';

import Event from './Event';

import { testEvents } from '../script/localdata';

const EventsCollection = ({
    events,
    editable,
    editCallback,
    removeCallback,
}) => {
    const [timeNow, setTimeNow] = useState(0);

    useEffect(() => {
        handleGetTime();

        // Set an interval to call handleGetTime every 1000ms (1 second)
        const timeInterval = setInterval(handleGetTime, 1000);
        // Clear the interval when the component is unmounted or when needed
        return () => clearInterval(timeInterval);
    }, []);

    const handleGetTime = async () => {
        const data = getCurrentTimeMillis();
        setTimeNow(data);
    };

    return (
        <div className='events-container'>
            {events.map((event, i) => (
                <Event
                    key={i}
                    event={event}
                    editable={editable}
                    editCallback={editCallback}
                    removeCallback={removeCallback}
                    timeNow={timeNow}
                />
            ))}
        </div>
    );
};

export default EventsCollection;
