import { useState, useEffect } from 'react';
import { updateParam } from '../script/fbAPI';
import { AppState } from '../context/AppContext';

const EarlyAccessCheckbox = (props) => {
    const { setSyncing } = AppState();
    const [hasEarlyAccess, setHasEarlyAccess] = useState(false);

    useEffect(() => {
        if (props.data.hasEarlyAccess) setHasEarlyAccess(true);
    }, []);

    const handleCheckboxChange = async (state) => {
        setSyncing(true);
        const user = props.data;
        await updateParam(user, 'hasEarlyAccess', state);
        setSyncing(false);
        setHasEarlyAccess(state);
    };

    return (
        <div>
            <input
                type='checkbox'
                onChange={(e) => handleCheckboxChange(e.target.checked)}
                checked={hasEarlyAccess}
            />
        </div>
    );
};

export default EarlyAccessCheckbox;
