import { HashLink as Link } from 'react-router-hash-link';
import { UserAuth } from '../context/AuthContext';
import { AppState } from '../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { MdArrowDropDown } from 'react-icons/md';

const UserMenu = () => {
    const { googleSignIn, logOut, user } = UserAuth();
    const navigate = useNavigate();
    const [name, setName] = useState('');

    const { native } = AppState();

    const handleGoogleSignIn = async () => {
        try {
            await googleSignIn();
        } catch (error) {
            console.log(error);
        }
    };

    const handleLogOut = async () => {
        try {
            await logOut();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        // Set user name
        if (user && user.displayName) {
            let formatted = user.displayName.replace(/ .*/, '').toLowerCase();
            formatted =
                formatted.charAt(0).toUpperCase() + formatted.substring(1);
            setName(formatted);
        }
    }, [user]);

    return (
        <div>
            {!user && native && (
                <button className='navlink' onClick={handleGoogleSignIn}>
                    Sign In
                </button>
            )}
            {!user && !native && (
                <Link to='/uhoh'>
                    <button className='navlink'>Sign In</button>
                </Link>
            )}
            {user && (
                <div className='dropdown'>
                    <div className='navlink'>
                        {name ? 'Hi ' + name : 'Syncing...'}
                        <MdArrowDropDown className='inline-icon-large' />
                    </div>
                    <div className='dropdown-content'>
                        {user.details && user.details.permissions > 1 && (
                            <Link className='navlink' to='/admin'>
                                Admin Panel
                            </Link>
                        )}
                        <Link className='navlink' to='/dashboard'>
                            Member Dashboard
                        </Link>
                        <button className='navlink' onClick={handleLogOut}>
                            Sign Out
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserMenu;
