const Announcements = () => {
    return (
        <div className='announcements-wrapper'>
            <div className='fade-off' />
            <p className='a-icon'>🎲</p>
            <p>Follow us on Instagram @laboardclub</p>
        </div>
    );
};

export default Announcements;
