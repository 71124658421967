import { discounts } from '../script/localdata.js';

const Discounts = () => {
    return (
        <div className='discounts-container'>
            {discounts.map((item, i) => (
                <a key={i} href={item.link} target='_blank'>
                    <div className='discount-wrapper'>
                        <p className='discount-sponsor'>{item.sponsor}</p>
                        <p className='discount-text'>{item.text}</p>
                        <div className='discount-code'>{item.code}</div>
                    </div>
                </a>
            ))}
        </div>
    );
};

export default Discounts;
