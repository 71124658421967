import background from '../assets/images/background.jpeg';

const Banner = () => {
    return (
        <div className='bg-image-wrapper'>
            <div className='tinter' />
            <img className='bg-image'
                    src={background} alt='Mountains' />
        </div>
    )
}

export default Banner;