import Hero from '../components/Hero';
import HomeContents from '../components/HomeContents';
import Footer from '../components/Footer';

const HomePage = () => {
    return (
        <div className='page'>
            <Hero />
            <HomeContents />
            <Footer />
        </div>
    );
};
export default HomePage;
