import { UserAuth } from '../context/AuthContext';
import { AppState } from '../context/AppContext';

import { merch } from '../script/localdata.js';

import MerchItem from './MerchItem';

const MerchContents = () => {
    const { user } = UserAuth();
    const { setSyncing } = AppState();

    return (
        <div className='h-center'>
            <div className='content-wrapper'>
                <h1>Merch</h1>

                <div className='merch-wrapper'>
                    {merch.map((item, i) => (
                        <MerchItem key={i} item={item} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default MerchContents;
