import { HashLink as Link } from 'react-router-hash-link';
import UserMenu from './UserMenu';
import Announcements from './Announcements';
import { useState, useEffect } from 'react';
import Logo from '../assets/images/logo.png';
import { MdMenu, MdClose } from 'react-icons/md';

const NavBar = () => {
    const [navbar, setNavbar] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);

    const scrollDidChange = () => {
        setNavbar(window.scrollY >= 60);
    };

    useEffect(() => {
        scrollDidChange();
    }, []);

    window.addEventListener('scroll', scrollDidChange);

    const handleOpenMenu = () => {
        setMenuOpen(true);
    };
    const handleCloseMenu = () => {
        setMenuOpen(false);
    };

    return (
        <div className='navbar-wrapper'>
            <Announcements />
            <div className={navbar ? 'navbar active' : 'navbar'}>
                <div className='content-wrapper space-between'>
                    <Link to='/'>
                        <img className='logo' src={Logo} />
                    </Link>
                    <button className='open-nav-menu' onClick={handleOpenMenu}>
                        <MdMenu className='icon' />
                    </button>
                    <div
                        className={`navlink-wrapper ${menuOpen ? 'open' : ''}`}
                    >
                        <button
                            className='close-nav-menu'
                            onClick={handleCloseMenu}
                        >
                            <MdClose className='icon' />
                        </button>
                        <Link to='/' className='navlink'>
                            
                        </Link>
                        <Link to='/#about' className='navlink'>
                            About Us
                        </Link>
                        <a
                            href='mailto:laskiandboardclub@gmail.com'
                            className='navlink'
                        >
                            Contact
                        </a>
                        <UserMenu />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavBar;
