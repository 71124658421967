import Skiier from '../assets/images/fallen-skiier.png';
import Arrow from '../assets/images/arrow.png';

import { Navigate } from 'react-router-dom';
import InApp from 'detect-inapp';

import { useState, useEffect } from 'react';

const NonNativePage = () => {
    const [browser, setBrowser] = useState('');
    useEffect(() => {
        if (!inBrowsers) {
            let displayBrowser = inapp.browser
                ? inapp.browser.charAt(0).toUpperCase() +
                  inapp.browser.slice(1) +
                  "'s"
                : 'This';
            setBrowser(displayBrowser);
        }
    }, []);

    const inapp = new InApp(
        navigator.userAgent || navigator.vendor || window.opera
    );
    const browsers = ['chrome', 'safari', 'ie', 'firefox'];
    const inBrowsers = browsers.includes(inapp.browser);
    if (inBrowsers) return <Navigate to='/' />;

    return (
        <div className='non-native-popup-wrapper'>
            <div className='instructions-wrapper'>
                <div className='text-wrapper'>
                    <h1>Uh oh...</h1>
                    <p className='nobreak'>
                        {browser} built-in browser blocks you from signing up as
                        a member.{' '}
                    </p>
                    {browser === "Instagram's" && (
                        <p className='nobreak'>
                            Instead, tap the three dots (...) in the top right,
                            then tap <span>Open in external browser</span>.
                        </p>
                    )}
                    {!(browser === "Instagram's") && (
                        <p className='nobreak'>
                            Instead, visit laboardclub.com in your phone's
                            native browser.
                        </p>
                    )}
                </div>
                {browser === "Instagram's" && (
                    <img className='arrow' src={Arrow} />
                )}
            </div>
            <div className='fallen-skiier-wrapper'>
                <img className='fallen-skiier' src={Skiier} />
            </div>
        </div>
    );
};

export default NonNativePage;
