import { useState, useEffect } from 'react';

const MemberName = (props) => {
    const [highlight, setHighlight] = useState(false);

    useEffect(() => {
        if (
            props.data.phone &&
            props.data.emergency &&
            props.data.schedule &&
            props.data.hasPaidDuesEarly &&
            !props.data.ikon
        )
            setHighlight(true);
    }, []);

    return (
        <div>
            <p className={highlight ? 'highlight-red' : ''}>
                {props.data.name}
            </p>
        </div>
    );
};

export default MemberName;
