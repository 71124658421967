import React from 'react';
import { Navigate } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';

const Protected = ({ children, perm }) => {
    const { user } = UserAuth();
    
    if (!user) {
        return <Navigate to='/' />;
    }
    if (perm > 1 && user.details && user.details.permissions <= 1) {
        return <Navigate to='/' />
    }
    
    return children;
}

export default Protected;