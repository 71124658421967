import { useState, useEffect } from 'react';
import { AppState } from '../context/AppContext';
import { handleUpdateIKON } from '../script/fbAPI';

const CodeInput = (props) => {
    const { setSyncing } = AppState();

    const [edit, setEdit] = useState(false);
    const [code, setCode] = useState('');

    const handleCodeChanged = (value) => {
        setCode(value);
    };

    const handleEditModeChange = async () => {
        if (edit) {
            setSyncing(true);
            await handleUpdateIKON(props.data.uid, code);
            setSyncing(false);
        }
        setEdit(!edit);
    };

    useEffect(() => {
        if (!props.data.ikon) setEdit(true);
        else {
            setCode(props.data.ikon);
        }
    }, []);

    return (
        <div>
            {edit && (
                <div className='text-input-wrapper'>
                    <input
                        className='text-input'
                        onChange={(e) => handleCodeChanged(e.target.value)}
                        value={code}
                        placeholder='Enter code'
                    />
                    <button
                        className='edit-mode-button'
                        onClick={() => handleEditModeChange()}
                    >
                        Save
                    </button>
                </div>
            )}
            {!edit && (
                <div className='text-input-wrapper'>
                    <p>{code}</p>
                    <button
                        className='edit-mode-button'
                        onClick={() => handleEditModeChange()}
                    >
                        Edit
                    </button>
                </div>
            )}
        </div>
    );
};

export default CodeInput;
