import Slider from 'react-slick';
import '../styles/slick.css';
import { useState } from 'react';

import Shaggys from '../assets/images/sponsors/shaggys.png';
import Lisco from '../assets/images/sponsors/lisco.png';
import TipsyElves from '../assets/images/sponsors/tipsyelves.png';
import Bern from '../assets/images/sponsors/bern.png';
import PitViper from '../assets/images/sponsors/pitviper.png';
import Burton from '../assets/images/sponsors/burton.png';
import Virtika from '../assets/images/sponsors/virtika.png';
import Kemper from '../assets/images/sponsors/kemper.png';

const Carousel = () => {
    const settings = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 10000,
        autoplaySpeed: 0,
        cssEase: 'linear',
        centerMode: true,
        arrows: false,
        variableWidth: true,
    };

    const [sponsors] = useState([
        Shaggys,
        Lisco,
        TipsyElves,
        Bern,
        PitViper,
        Burton,
        Virtika,
        Kemper,
    ]);

    return (
        <div className='carousel-wrapper'>
            <div className='carousel-screen' />
            <Slider {...settings}>
                {sponsors.map((sponsor, i) => (
                    <div key={i} className='sponsor-wrapper'>
                        <img className='sponsor' src={sponsor} alt={sponsor} />
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;
