const Question = ({ data, value, callback }) => {
    return (
        <div>
            {data.type == 'text' && (
                <div>
                    <p>{data.title}</p>
                    <div className='text-input-wrapper padded border'>
                        <input
                            className='text-input'
                            onChange={(e) => callback(e.target.value)}
                            value={value}
                            placeholder='Short answer text'
                        />
                    </div>
                </div>
            )}
            {data.type == 'radio' && (
                <div className='radio-wrapper'>
                    <p>{data.title}</p>
                    {data.options.map((option, i) => (
                        <div className='radio-option-wrapper' key={i}>
                            <input
                                className='radio-button'
                                type='radio'
                                value={i}
                                checked={value === i}
                                onChange={(e) => callback(i)}
                            />
                            {option}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Question;
