import { useState, useEffect } from 'react';

import { BiPlus, BiMinus } from 'react-icons/bi';

const CustomQuestionsPanel = ({ questions, setQuestions }) => {
    const [options, setOptions] = useState([]);

    useEffect(() => {
        let temp = [];
        // Split options array into string
        for (var i = 0; i < questions.length; i++) {
            const question = questions[i];
            if (question.type == 'radio') {
                let optionsString = '';
                if (!question.options) question.options = [];
                for (var j = 0; j < question.options.length; j++) {
                    const option = question.options[j];
                    optionsString += option;
                    if (j != question.options.length - 1) optionsString += ', ';
                }
                temp.push(optionsString);
            } else temp.push('');
        }
        setOptions(temp);
    }, [questions]);

    const handleAddQuestion = () => {
        let temp = [...questions];
        temp.push({
            type: 'text',
        });
        setQuestions(temp);
    };

    const handleRemoveQuestion = (i) => {
        let temp = [...questions];
        temp.splice(i, 1);
        setQuestions(temp);
    };

    const handleTypeChange = (value, i) => {
        let temp = [...questions];
        questions[i].type = value;
        setQuestions(temp);
    };

    const handleTitleChange = (value, i) => {
        let temp = [...questions];
        temp[i].title = value;
        setQuestions(temp);
    };

    const handleOptionsChange = (value, i) => {
        let temp = [...questions];
        temp[i].options = value.split(', ');
        setQuestions(temp);
    };

    return (
        <div>
            <div className='header-wrapper-space-between'>
                <h3>Custom questions</h3>
                <button className='inline-square' onClick={handleAddQuestion}>
                    <BiPlus />
                </button>
            </div>
            {questions.map((question, i) => (
                <div key={i}>
                    <div className='header-wrapper-space-between'>
                        <p>{`Question ${i + 1}`}</p>
                        <button
                            className='inline-square'
                            onClick={() => handleRemoveQuestion(i)}
                        >
                            <BiMinus />
                        </button>
                    </div>
                    <div className='indented'>
                        <p>Type</p>
                        <select
                            className='select'
                            onChange={(e) =>
                                handleTypeChange(e.target.value, i)
                            }
                            value={question.type}
                        >
                            <option value='text'>Text field</option>
                            <option value='radio'>Radio buttons</option>
                        </select>
                        <p>Title</p>
                        <div className='text-input-wrapper padded border'>
                            <input
                                className='text-input'
                                onChange={(e) =>
                                    handleTitleChange(e.target.value, i)
                                }
                                value={question.title}
                                placeholder='How many glizzies fit in your mouth?'
                            />
                        </div>
                        {question.type == 'radio' && (
                            <div>
                                <p>Options (comma-space ', ' separated)</p>
                                <div className='text-input-wrapper padded border'>
                                    <input
                                        className='text-input'
                                        onChange={(e) =>
                                            handleOptionsChange(
                                                e.target.value,
                                                i
                                            )
                                        }
                                        value={options[i]}
                                        placeholder='One, Two, Three, Four, Biz'
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CustomQuestionsPanel;
