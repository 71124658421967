import { FaExternalLinkAlt } from 'react-icons/fa';

const ScheduleLink = (props) => {
    return (
        <div>
            {props.data.schedule && (
                <a target='_blank' href={props.data.schedule}>
                    <FaExternalLinkAlt className='inline-icon' />
                </a>
            )}
        </div>
    );
};

export default ScheduleLink;
