import Header from '../components/Header';
import DashboardContents from '../components/DashboardContents';
import Footer from '../components/Footer';
import SyncModal from '../components/SyncModal';
import EventModal from '../components/EventModal';
import ConfirmModal from '../components/ConfirmModal';

const Dashboard = () => {
    return (
        <div className='page'>
            <EventModal />
            <ConfirmModal />
            <SyncModal />
            <Header />
            <DashboardContents />
            <Footer />
        </div>
    );
};
export default Dashboard;
