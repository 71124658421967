import { AiOutlineSchedule, AiOutlinePhone } from 'react-icons/ai';
import { RiCake2Line } from 'react-icons/ri';
import { MdImageSearch, MdOutlineEdit, MdCheck } from 'react-icons/md';

import { useState, useEffect } from 'react';
import { UserAuth } from '../context/AuthContext';
import { uploadSchedule, updateParam } from '../script/fbAPI';
import { AppState } from '../context/AppContext';

const VerifyAccount = ({ callback }) => {
    const [file, setFile] = useState();
    const [num1, setNum1] = useState();
    const [num2, setNum2] = useState();
    const [date, setDate] = useState();

    // Edit state variables
    const [schedule, setSchedule] = useState(false);
    const [editNum1, setEditNum1] = useState(false);
    const [editNum2, setEditNum2] = useState(false);
    const [editDate, setEditDate] = useState(false);
    const [extra, setExtra] = useState([null, null, null]);

    const { user } = UserAuth();
    const { setSyncing } = AppState();

    // See which user details have already been configured
    useEffect(() => {
        const details = user.details;
        if (details.schedule) setSchedule(true);
        if (!details.phone) setEditNum1(true);
        else setNum1(details.phone);
        if (!details.emergency) setEditNum2(true);
        else setNum2(details.emergency);
        if (!details.dob) setEditDate(true);
        else setDate(details.dob);
    }, []);

    const handleUploadSchedule = async () => {
        if (!file) return;
        setSyncing(true);
        let url = await uploadSchedule(user, file);
        setSyncing(false);
        if (url) {
            callback('schedule');
            setSchedule(true);
        }
    };

    // Formats number and sets value of input
    const handleNum1Changed = (value) => {
        const formatted = formatPhoneNumber(value);
        setNum1(formatted);
        let temp = [...extra];
        temp[0] = null;
        setExtra(temp);
    };
    const handleNum2Changed = (value) => {
        const formatted = formatPhoneNumber(value);
        setNum2(formatted);
        let temp = [...extra];
        temp[1] = null;
        setExtra(temp);
    };
    const handleDateChanged = (value) => {
        const formatted = formatDate(value);
        setDate(formatted);
    };

    // Edit mode
    const handleEditModeChange = async (input) => {
        if (input === 'num1') {
            if (editNum1 === true) {
                if (num1.length === 14) {
                    // Num Validation
                    setSyncing(true);
                    await updateParam(user, 'phone', num1);
                    setEditNum1(false);
                    callback('num1');
                    setSyncing(false);
                } else {
                    let temp = [...extra];
                    temp[0] = 'red-border';
                    setExtra(temp);
                }
            } else setEditNum1(true);
        }
        if (input === 'num2') {
            if (editNum2 === true) {
                if (num2.length === 14) {
                    // Num Validation
                    setSyncing(true);
                    await updateParam(user, 'emergency', num2);
                    setEditNum2(false);
                    callback('num2');
                    setSyncing(false);
                } else {
                    let temp = [...extra];
                    temp[1] = 'red-border';
                    setExtra(temp);
                }
            } else setEditNum2(true);
        }
        if (input === 'date') {
            if (editDate === true) {
                if (date.length === 10 && isValid(date)) {
                    // Num Validation
                    setSyncing(true);
                    await updateParam(user, 'dob', date);
                    setEditDate(false);
                    callback('date');
                    setSyncing(false);
                } else {
                    let temp = [...extra];
                    temp[2] = 'red-border';
                    setExtra(temp);
                }
            } else setEditDate(true);
        }
    };

    // Validates month, day, year sections of date string
    function isValid(date) {
        const m = parseInt(date.slice(0, 2));
        const d = parseInt(date.slice(3, 5));
        const y = parseInt(date.slice(6, 10));
        const valid =
            m > 0 && m <= 12 && d > 0 && d <= 31 && y > 1900 && y <= 2010;
        return valid;
    }

    // Formats potentially invalid string to valid format
    function formatPhoneNumber(value) {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
            return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
            3,
            6
        )}-${phoneNumber.slice(6, 10)}`;
    }
    function formatDate(value) {
        if (!value) return value;
        const date = value.replace(/[^\d]/g, '');
        const dateLength = date.length;
        if (dateLength < 3) return date;
        if (dateLength < 5) {
            return `${date.slice(0, 2)}-${date.slice(2)}`;
        }
        return `${date.slice(0, 2)}-${date.slice(2, 4)}-${date.slice(4, 8)}`;
    }

    return (
        <div>
            <p>
                Fill out your account details to gain access to the IKON
                discount form below.
            </p>
            <div className='verify-wrapper'>
                <div className='verify-block'>
                    <div className='verify-header'>
                        <AiOutlineSchedule className='verify-icon' />
                        <h3 className='no-padding'>UCLA Schedule</h3>
                    </div>
                    <p className='verify-desc'>
                        Your class schedule (MyUCLA Class Planner)
                    </p>
                    {!schedule && (
                        <div className='file-search-wrapper'>
                            <MdImageSearch className='file-select-icon' />
                            <input
                                className='verify-file-input'
                                type='file'
                                accept='image/png, image/jpeg'
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                            <button
                                className='primary file-upload-button'
                                onClick={handleUploadSchedule}
                            >
                                Upload
                            </button>
                        </div>
                    )}
                    {schedule && <p>Schedule uploaded.</p>}
                </div>

                <div className='verify-block'>
                    <div className='verify-header'>
                        <AiOutlinePhone className='verify-icon' />
                        <h3 className='no-padding'>Contact details</h3>
                    </div>
                    <p className='verify-desc'>Your phone number</p>
                    {editNum1 && (
                        <div className='text-input-wrapper margin-bottom'>
                            <input
                                className={
                                    extra[0]
                                        ? 'text-input red-border'
                                        : 'text-input'
                                }
                                onChange={(e) =>
                                    handleNum1Changed(e.target.value)
                                }
                                value={num1}
                                placeholder='(123) 456-7890'
                            />
                            <button
                                className='edit-mode-button'
                                onClick={() => handleEditModeChange('num1')}
                            >
                                Save
                            </button>
                        </div>
                    )}
                    {!editNum1 && (
                        <div className='text-input-wrapper margin-bottom'>
                            <p>{num1}</p>
                            <button
                                className='edit-mode-button'
                                onClick={() => handleEditModeChange('num1')}
                            >
                                Edit
                            </button>
                        </div>
                    )}
                    <p className='verify-desc'>
                        Emergency contact phone number
                    </p>
                    {editNum2 && (
                        <div className='text-input-wrapper'>
                            <input
                                className={`text-input ${extra[1]}`}
                                onChange={(e) =>
                                    handleNum2Changed(e.target.value)
                                }
                                value={num2}
                                placeholder='(123) 456-7890'
                            />
                            <button
                                className='edit-mode-button'
                                onClick={() => handleEditModeChange('num2')}
                            >
                                Save
                            </button>
                        </div>
                    )}
                    {!editNum2 && (
                        <div className='text-input-wrapper'>
                            <p>{num2}</p>
                            <button
                                className='edit-mode-button'
                                onClick={() => handleEditModeChange('num2')}
                            >
                                Edit
                            </button>
                        </div>
                    )}
                </div>

                <div className='verify-block'>
                    <div className='verify-header'>
                        <RiCake2Line className='verify-icon' />
                        <h3 className='no-padding'>Date of Birth</h3>
                    </div>
                    <p className='verify-desc'>
                        DOB (must match your DOB registered with IKON)
                    </p>
                    {editDate && (
                        <div className='text-input-wrapper'>
                            <input
                                className={`text-input ${extra[2]}`}
                                onChange={(e) =>
                                    handleDateChanged(e.target.value)
                                }
                                value={date}
                                placeholder='mm-dd-yyyy'
                            />
                            <button
                                className='edit-mode-button'
                                onClick={() => handleEditModeChange('date')}
                            >
                                Save
                            </button>
                        </div>
                    )}
                    {!editDate && (
                        <div className='text-input-wrapper'>
                            <p>{date}</p>
                            <button
                                className='edit-mode-button'
                                onClick={() => handleEditModeChange('date')}
                            >
                                Edit
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default VerifyAccount;
