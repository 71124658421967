import { FaExternalLinkAlt } from 'react-icons/fa';
import { TbEdit, TbTrash, TbDatabase } from 'react-icons/tb';

import {
    FBTimestampToMillis,
    countDrivers,
    countGeneral,
    removeEvent,
} from '../script/fbAPI';

import { AppState } from '../context/AppContext';
import { UserAuth } from '../context/AuthContext';

import { useState, useEffect } from 'react';

const Event = ({ event, editable }) => {
    const { setEvent, setSyncing, setConfirm } = AppState();
    const { user } = UserAuth();

    const removeEventCallback = async () => {
        setSyncing(true);
        await removeEvent(event.uid);
        setSyncing(false);
        // Refresh events
        window.location.reload();
    };

    const handleRemoveEvent = async (id) => {
        const confirm = {
            actionRequired: true,
            text: 'Permanently remove this event?',
            callback: removeEventCallback,
        };
        await setConfirm(confirm);
    };

    const handleEditModeChange = (type) => {
        let temp = { ...event };
        temp.general.cur_qty = generalQty;
        temp.drivers.cur_qty = driversQty;
        temp.attending = attending;

        setEvent({
            data: temp,
            callback: () => {
                window.location.reload();
            },
            type: type,
        });
    };

    // Get attendance (on interval)
    const [generalQty, setGeneralQty] = useState(0);
    const [driversQty, setDriversQty] = useState(0);
    const [eventFull, setEventFull] = useState(false);
    const [attending, setAttending] = useState(false);

    const isUserAttending = () => {
        if (!event || typeof event.responses !== 'object') {
            return false;
        }
        return event.responses[user.uid] && event.responses[user.uid].paid;
    };

    useEffect(() => {
        if (!editable) {
            const attending = isUserAttending();
            setAttending(attending);
        }
        getAttendance();
        setInterval(getAttendance, 1000);
    }, []);

    const getAttendance = async () => {
        const generalQty = await countGeneral(event);
        const driversQty = await countDrivers(event);
        setGeneralQty(generalQty);
        setDriversQty(driversQty);

        const full =
            generalQty >= event.general.max_qty &&
            driversQty >= event.drivers.max_qty;

        setEventFull(full);
    };

    return (
        <div className='event'>
            <div className='event-header-wrapper'>
                <h3 className='no-padding'>{event.title}</h3>
                {editable && (
                    <div className='edit-buttons-wrapper'>
                        <button
                            className='edit-mode-button'
                            onClick={() => handleEditModeChange('edit')}
                        >
                            <TbEdit className='edit-icon' />
                        </button>
                        <button
                            className='edit-mode-button'
                            onClick={() => handleEditModeChange('responses')}
                        >
                            <TbDatabase className='edit-icon' />
                        </button>
                        {/* <button
                            className='edit-mode-button'
                            onClick={handleRemoveEvent}
                        >
                            <TbTrash className='edit-icon' />
                        </button> */}
                    </div>
                )}
                {!editable && !eventFull && !attending && (
                    <div className='event-status-wrapper open'>OPEN</div>
                )}
                {!editable && eventFull && !attending && (
                    <div className='event-status-wrapper full'>FULL</div>
                )}
                {!editable && attending && (
                    <div className='event-status-wrapper attending'>PAID</div>
                )}
            </div>
            <div>
                <p className='event-desc'>{event.desc_short}</p>

                <button
                    className='secondary'
                    onClick={() => handleEditModeChange('regular')}
                >
                    {editable && 'Preview'}
                    {!editable && 'See more'}
                </button>
            </div>
        </div>
    );
};

export default Event;
