import Header from '../components/Header';
import MerchContents from '../components/MerchContents';
import Footer from '../components/Footer';
import SyncModal from '../components/SyncModal';

const MerchPage = () => {
    return (
        <div className='page'>
            <SyncModal />
            <Header />
            <MerchContents />
            <Footer />
       </div>
    )
}
export default MerchPage;