// Pages
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import AdminPage from './pages/AdminPage';
import MerchPage from './pages/MerchPage';
import NonNativePage from './pages/NonNativePage';
// Components
import { BrowserRouter as HashRouter, Routes, Route } from 'react-router-dom';
import Protected from './components/Protected';
// Context
import { AuthContextProvider } from './context/AuthContext';
import { AppContextProvider } from './context/AppContext';

const DOWNTIME = false;

function App() {
    if (DOWNTIME) {
        return (
            <div className='downtime'>
                We're currently making some big changes, come back later!
            </div>
        )
    }

    return (
        <AppContextProvider>
            <AuthContextProvider>
                <HashRouter>
                    <Routes>
                        <Route exact path='/' element={<HomePage />} />
                        <Route exact path='/uhoh' element={<NonNativePage />} />
                        <Route
                            exact
                            path='/dashboard'
                            element={
                                <Protected>
                                    <Dashboard />
                                </Protected>
                            }
                        />
                        <Route
                            exact
                            path='/admin'
                            element={
                                <Protected perm={2}>
                                    <AdminPage />
                                </Protected>
                            }
                        />
                        <Route exact path='/merch' element={<MerchPage />} />
                    </Routes>
                </HashRouter>
            </AuthContextProvider>
        </AppContextProvider>
    );
}

export default App;
