import NavBar from './NavBar';
import Banner from './Banner';
import Carousel from './Carousel';
import skis from '../assets/images/skis.png';

const Hero = () => {
    return (
        <div className='hero'>
            <NavBar />
            <Banner />    
            <div className='h-center'>
                <div className='content-wrapper space-between'>
                    <img className='skis-image-horizontal'
                            src={skis} alt='Our custom skis' />
                    <div className='bigtext-wrapper'>
                        <h1>
                            Los Angeles 
                        </h1>
                        <h1>
                            Ski & Board Club
                        </h1>
                    </div>
                    <img className='skis-image-vertical'
                        src={skis} alt='Our custom skis' />
                </div>
            </div>
            <Carousel />
        </div>
    )
}

export default Hero;